import html2canvas from "html2canvas"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isNil, isEmpty } from "ramda"
import { lastEventId as sentryLastEventId } from "@sentry/browser"

import { submitTicket } from "entities/doorbell/doorbell.actions"

import { uploadFilesToDoorbell } from "entities/doorbell/doorbell.api"

import { DoorbellFormSection } from "entities/doorbell/doorbell-form.section"
import { TutorialMenuSection } from "entities/doorbell/tutorial-menu.section"
import { TUTORIAL_LINKS } from "lib/leeruniek/constants"

import { trackSendForm } from "entities/doorbell/doorbell-form.tracking"
import { t } from "entities/doorbell/doorbell-form.i18n"
import MLUDialog, { DialogSize } from "components/ui/mludialog/mludialog"
import MLUSnackbar from "components/ui/mlusnackbar/mlusnackbar"
import { LUActions } from "webclient-ui/components"

export const DoorbellFormContainer = ({
  sessionFullName,
  sessionUserName,
  sessionEmail,
  sessionIsDemo,
  isDoorbellReady = false,
  sessionSchoolName = t("not_found"),
  sessionSchoolBRIN = t("not_found"),
  showDoorbellForm = false,
  isLoading,
  isVisible,
  ticketOptions,
  onSubmit,
  onClose,
}) => {
  const translatedTicketOptions = ticketOptions.map((option) => ({
    label: t(`doorbell_ticket_option_${option.value}`),
    value: option.value,
  }))
  const [ticketMessage, setTicketMessage] = useState()
  const [ticketType, setTicketType] = useState(
    translatedTicketOptions.length === 1 ? translatedTicketOptions[0] : null,
  )
  const [ticketSendScreenshot, setTicketSendScreenshot] = useState(true)
  const [hasTicketMessageFocus, setHasTicketMessageFocus] = useState(false)
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false)
  const [isDoorbellFormVisible, setIsDoorbellFormVisible] =
    useState(showDoorbellForm)

  const [filesSelectedForUpload, setFilesSelectedForUpload] = useState([])

  const [
    isUserAttemptedToUploadTooLargeFile,
    setIsUserAttemptedToUploadTooLargeFile,
  ] = useState(null)

  const cleanState = () => {
    setTicketMessage("")
    setTicketType(null)
    setTicketSendScreenshot(true)
    setIsDoorbellFormVisible(false)

    setFilesSelectedForUpload([])

    setIsUserAttemptedToUploadTooLargeFile(false)
  }

  useEffect(() => {
    const postSubmit = isLoading && isDoorbellReady
    if (postSubmit) {
      cleanState()
      onSubmit()
    }
  }, [isDoorbellReady])

  const handleTypeSelect = (value) => {
    setTicketType(value)
  }

  const handleTicketMessageChange = (event) => {
    setTicketMessage(event.target.value)
  }

  const handleTicketScreenshotChange = (event) => {
    setTicketSendScreenshot(event.currentTarget.checked)

    setHasTicketMessageFocus(false)
  }

  const handleDialogClose = () => {
    if (!isLoading) {
      onClose()
      cleanState()
    }
  }

  const handleDialogSave = async () => {
    if (!isLoading) {
      /*
       * Screenshot WEBP_REACT_ROOT_ID so we dont include the dialog itself (
       * the dialog appends itself into body)
       */
      const screenShotCanvas = ticketSendScreenshot
        ? await html2canvas(document.getElementById(WEBP_REACT_ROOT_ID))
        : null

      const attachments =
        filesSelectedForUpload.length > 0
          ? await uploadFilesToDoorbell(filesSelectedForUpload)
          : null

      submitTicket({
        email: sessionEmail,
        name: sessionFullName,
        message: ticketMessage,
        screenshot: screenShotCanvas && screenShotCanvas.toDataURL(),
        ...(attachments ? { attachments } : null),
        properties: {
          School: sessionSchoolName,
          BRIN: sessionSchoolBRIN,
          Type: translatedTicketOptions.find(
            (option) => option.value === ticketType,
          ),
          IsDemo: sessionIsDemo,
          Username: sessionUserName,
          SentryEventId: sentryLastEventId(),
        },
      }).then(() => {
        trackSendForm(
          ticketMessage,
          ticketType.label,
          filesSelectedForUpload?.length ?? 0,
          filesSelectedForUpload?.map((file) => file.type) ?? [],
          ticketSendScreenshot,
        )
        handleDialogClose()
        setIsSnackbarVisible(true)
      })
    }
  }

  const handleSnackbarClose = () => {
    setIsSnackbarVisible(false)
  }

  const canSend =
    !isNil(ticketMessage) &&
    !isEmpty(ticketMessage) &&
    !isNil(ticketType) &&
    !isEmpty(ticketType)

  return (
    <React.Fragment>
      <MLUDialog
        key="doorbell-dialog"
        title={t("doorbell_dialog_title")}
        color="yellow"
        open={isVisible}
        loading={isLoading}
        size={DialogSize.AUTO}
        actions={
          isDoorbellFormVisible ? (
            <LUActions
              actions={[
                {
                  color: "lightGray",
                  label: t("cancel"),
                  icon: "fa fa-times",
                  isMuted: true,
                  onClick: handleDialogClose,
                },
                {
                  color: "yellow",
                  label: t(isLoading ? "submiting" : "submit"),
                  icon: "fa fa-paper-plane",
                  disabled: !canSend,
                  onClick: handleDialogSave,
                },
              ]}
              align="right"
              hasSeparator={true}
              hasSeparatorLine={false}
            />
          ) : undefined
        }
        onClose={handleDialogClose}>
        {!isDoorbellFormVisible ? (
          <TutorialMenuSection
            links={TUTORIAL_LINKS}
            onSupportFormShowClick={() => setIsDoorbellFormVisible(true)}
          />
        ) : (
          <DoorbellFormSection
            ticketOptions={translatedTicketOptions}
            tutorialLinks={TUTORIAL_LINKS}
            isLoading={isLoading}
            ticketMessage={ticketMessage}
            selectedTicketType={ticketType}
            isScreenshotAttached={ticketSendScreenshot}
            hasAutoFocus={hasTicketMessageFocus}
            onTicketTypeSelect={handleTypeSelect}
            onTicketMessageChange={handleTicketMessageChange}
            onScreenshotToggle={handleTicketScreenshotChange}
            filesSelectedForUpload={filesSelectedForUpload}
            setFilesSelectedForUpload={setFilesSelectedForUpload}
            isUserAttemptedToUploadTooLargeFile={
              isUserAttemptedToUploadTooLargeFile
            }
            setIsUserAttemptedToUploadTooLargeFile={
              setIsUserAttemptedToUploadTooLargeFile
            }
          />
        )}
      </MLUDialog>
      <MLUSnackbar
        closable
        message={t("doorbell_notify_success")}
        open={isSnackbarVisible}
        onClose={handleSnackbarClose}
      />
    </React.Fragment>
  )
}
export default connect((state) => {
  const schoolId = state.session.schoolId
  const sessionSchool = state.session.schools.find(
    (school) => school.id === schoolId,
  )

  return {
    isDoorbellReady:
      !isNil(state.doorbell.submit.data.ok) &&
      state.doorbell.submit.data.ok === true,
    isLoading: state.doorbell.submit.isLoading,
    sessionSchoolName: schoolId ? sessionSchool.name : null,
    sessionSchoolBRIN: schoolId ? sessionSchool.brinCode : null,
    sessionFullName: state.session.user.fullName,
    sessionUserName: state.session.user.username,
    sessionEmail: state.session.user.email,
    sessionIsDemo: state.session.user.isDemo,
  }
})(DoorbellFormContainer)

DoorbellFormContainer.propTypes = {
  sessionFullName: PropTypes.string.isRequired,
  sessionUserName: PropTypes.string.isRequired,
  sessionEmail: PropTypes.string.isRequired,
  sessionIsDemo: PropTypes.bool.isRequired,
  sessionSchoolName: PropTypes.string,
  sessionSchoolBRIN: PropTypes.string,
  isDoorbellReady: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,

  // upstream
  isVisible: PropTypes.bool.isRequired,
  ticketOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
