import React from "react"

import MLUDialog, {
  ActionsAlignment,
  DialogSize,
} from "components/ui/mludialog/mludialog"
import { LUCheckbox } from "webclient-ui/components"
import css from "./modal-notification.module.scss"
import { t } from "./modal-notification.i18n.js"

interface ModalNotificationProps {
  open: boolean
  title: string
  children: React.ReactNode
  actions: React.ReactNode[]
  hideNotification?: boolean
  onHideNotificationToggled?: () => void
}

const ModalNotification = ({
  actions,
  children,
  hideNotification,
  onHideNotificationToggled,
  open,
  title,
}: ModalNotificationProps) => {
  return (
    <MLUDialog
      open={open}
      className={css["notification-container"]}
      color="yellow"
      size={DialogSize.AUTO}
      title={title}
      actions={actions}
      actionsAlignment={ActionsAlignment.CENTER}>
      {children}
      {hideNotification !== undefined && (
        <LUCheckbox
          className={css["hide-notification"]}
          label={t("hide-notification")}
          size="small"
          isChecked={hideNotification}
          onChange={onHideNotificationToggled}
        />
      )}
    </MLUDialog>
  )
}

export default ModalNotification
