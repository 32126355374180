import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["new-school-year-notification"]: {
    nl: `Om het nieuwe schooljaar fris te starten, raden we jullie aan oude<br/>
         accounts te verwijderen en nieuwe aan te maken voor de eventuele<br/>
         nieuwe collega's.<br /><br />
         Ga naar beheer > Gebruikers om dit bij te werken.<br /><br />
         Succes met de gouden weken.<br />
         Team Leeruniek<br />`,
    en: `To start the new school year fresh, we advise you to delete old
         accounts and create new ones for any new colleagues.<br /><br />
         Go to management > Users to update this.<br /><br />
         Good luck with the golden weeks.<br />
         Team Leeruniek<br />`,
  },

  ["hide-new-school-notification"]: {
    nl: "Laat dit bericht niet meer zien",
    en: "Do not show this message again",
  },

  ["new-schoolyear"]: {
    nl: "Nieuw schooljaar",
    en: "New school year",
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
