import React, { lazy, Suspense } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import HTML5Backend from "react-dnd-html5-backend"
import { Switch, Route, Redirect, withRouter, Prompt } from "react-router-dom"
import { connect } from "react-redux"
import { DragDropContext as dragDropContext } from "react-dnd"
import { head, isNil, isEmpty, equals } from "ramda"
import { LULoader } from "webclient-ui/components"

import * as RouterUtils from "lib/core/router.utils"
import { hasDirtyForms } from "lib/core/forms.utils"
import buildGroups from "lib/leeruniek/build-groups"

import { LUSidemenu } from "components/sidemenu/sidemenu"

import { changeSchool, clearErrors } from "entities/sessions/sessions.actions"
import { getSchool } from "entities/schools/schools.actions"

// Sub Layouts
const SchoolsLayout = lazy(() => import("entities/schools/schools.layout"))
const PupilsLayout = lazy(() => import("entities/pupils/pupils.layout"))
const GroupsLayout = lazy(() => import("entities/groups/groups.layout"))
const YearGradesLayout = lazy(
  () => import("entities/yeargrades/yeargrades.layout"),
)

const AccountEditPage = lazy(
  () => import("entities/account__edit.page/account-edit.page"),
)
const AdminTemplatesPage = lazy(
  () => import("entities/admin__templates.page/admin__templates.page"),
)
const AdminPermissionsPage = lazy(
  () => import("entities/admin__permissions.page/admin__permissions.page"),
)
const AdminPupilsPage = lazy(
  () => import("entities/admin__pupils.page/admin__pupils.page"),
)
const AdminUploadPage = lazy(
  () => import("entities/admin__upload.page/admin__upload.page"),
)

import { SchoolsWrapper } from "entities/schools/schools.wrapper"

import ClientErrorView from "components/error/client_error_view"
import SchoolDisconnectedNotification from "entities/school-disconnected-notification/school-disconnected-notification"

import css from "entities/user.layout/user.module.scss"

const mapStateToProps = (store) => {
  const routeParams = RouterUtils.getParams(
    store.router.location.pathname,
    "groupsProxy",
  )
  const schoolRouteParams = RouterUtils.getParams(
    store.router.location.pathname,
    "school",
  )

  const { schools, user, errors } = store.session

  const schoolIdRouteParam = parseInt(schoolRouteParams.schoolId, 10)
  const schoolIdSessionParam = store.session.schoolId

  const schoolId = equals(schoolIdRouteParam, NaN)
    ? schoolIdSessionParam
    : schoolIdRouteParam

  const currentSchool = schools.find((school) => school.id === schoolId)

  const schoolNotFound = isNil(currentSchool) || isEmpty(currentSchool)

  /*
   * only set yearclass if data is in store AND currently on a page related to
   * the yearclass
   */
  const hasYearClass =
    !isNil(routeParams.yearclassId) && !isNil(store.yearclass)

  return {
    schoolNotFound,
    sessionSchoolName: schoolNotFound ? undefined : currentSchool.name,
    currentURL: store.router.location.pathname,
    schoolId,
    user,
    yearClassId: hasYearClass ? store.yearclass.id : null,
    yearClassGrade: hasYearClass ? parseInt(store.yearclass.grade, 10) : null,
    hasGoals: store.yearclass.hasGoals,
    hasMultipleSchools: schools.length > 1,
    isSchoolSuperuser: !isNil(store.school.school?.isSchoolSuperuser)
      ? store.school.school.isSchoolSuperuser
      : false,
    schoolLoading: store.school.schoolLoading,
    school: store.school.school,
    yearclasses: store.school.school.classes,
    hasAccessError: !isNil(errors.accessError),
    hasNotFoundError: !isNil(errors.notFoundError),
    testProvidersLoading: store.school.testProviders.isLoading,
    nationalTestsLoading: store.school.nationalTests.isLoading,
    subjectsLoading: store.subjects.isLoading,
    years: store.school.history.calendarYears || [],
  }
}

export const UserLayoutPure = ({
  schoolId,
  schoolNotFound,
  sessionSchoolName,
  currentURL,
  hasMultipleSchools,
  yearclasses,
  isSchoolSuperuser,
  yearClassId = null,
  yearClassGrade = null,
  hasGoals,
  getSchool,
  changeSchool,
  clearErrors,
  schoolLoading,
  testProvidersLoading,
  nationalTestsLoading,
  subjectsLoading,
  hasAccessError,
  hasNotFoundError,
  user,
  location,
  school,
  years,
}) => {
  React.useEffect(() => {
    if (schoolId !== school.id) {
      getSchool(schoolId).then(({ payload: school }) => {
        return Promise.all([changeSchool(school)])
      })
    }
  }, [schoolId])

  const allowedGroups = !isNil(yearclasses)
    ? buildGroups(yearclasses, "class", user.currentSchoolYear)
    : []

  const hasOneSchool = !hasMultipleSchools
  const hasOneGroup = allowedGroups?.length === 1

  const homepagePath = !isNil(yearclasses)
    ? RouterUtils.getHomepageURL({
        schoolId,
        groupId: head(allowedGroups)?.groupId,
        hasOneSchool,
        hasOneGroup,
      })
    : RouterUtils.get("schoolsList")

  const isLoading =
    schoolLoading ||
    testProvidersLoading ||
    nationalTestsLoading ||
    subjectsLoading

  return isLoading ? (
    <LULoader isLoading={isLoading} />
  ) : (
    <div className={css["loggedin-wrapper"]} data-testid={"user_layout"}>
      <div className={css["loggedin-layout"]}>
        <SchoolDisconnectedNotification />
        <SchoolsWrapper schoolId={schoolId}>
          <LUSidemenu
            logoURL={homepagePath}
            currentURL={currentURL}
            schoolId={schoolId}
            schoolNotFound={schoolNotFound}
            schoolName={sessionSchoolName}
            hasMultipleSchools={hasMultipleSchools}
            yearClassId={yearClassId}
            yearClassGrade={yearClassGrade}
            isSchoolSuperuser={isSchoolSuperuser}
            user={user}
            isGoalsVisible={hasGoals}
          />
          <main className={cx(css["page-wrapper"], "clearfix")}>
            {hasAccessError || hasNotFoundError ? (
              <ClientErrorView
                isAccessError={hasAccessError}
                isNotFound={hasNotFoundError}
                onErrorsClean={clearErrors}
              />
            ) : (
              <Suspense
                fallback={<LULoader isLoading={true} isFullScreen={false} />}>
                <Switch>
                  <Route
                    path={RouterUtils.get("years")}
                    component={YearGradesLayout}
                  />
                  <Route
                    path={RouterUtils.get("groups")}
                    component={GroupsLayout}
                  />
                  <Route
                    path={RouterUtils.get("pupils")}
                    component={PupilsLayout}
                  />
                  <Route
                    path={RouterUtils.get("account__edit")}
                    component={AccountEditPage}
                  />
                  <Route
                    path={RouterUtils.get("admin__templates")}
                    component={AdminTemplatesPage}
                  />
                  <Route
                    path={RouterUtils.get("admin__permissions")}
                    component={AdminPermissionsPage}
                  />
                  <Route
                    path={RouterUtils.get("admin__pupils")}
                    component={AdminPupilsPage}
                  />
                  <Route
                    path={RouterUtils.get("admin__upload")}
                    component={AdminUploadPage}
                  />
                  <Route
                    path={RouterUtils.get("schoolsList")}
                    component={SchoolsLayout}
                  />
                  <Route
                    path={RouterUtils.get("appNotFound")}
                    render={(routerProps) => (
                      <ClientErrorView isNotFound={true} {...routerProps} />
                    )}
                  />
                  <Redirect exact={true} from="/app" to={homepagePath} />
                  <Route
                    path={RouterUtils.get("appNotFound")}
                    render={(routerProps) => (
                      <ClientErrorView isNotFound={true} {...routerProps} />
                    )}
                  />
                </Switch>
              </Suspense>
            )}
            <Prompt
              message={(nextLocation) => {
                const shouldIgnoreBlock =
                  RouterUtils.getSchoolPageNotBlockingCondition(
                    location,
                    nextLocation,
                    years[years.length - 1],
                  )

                return hasDirtyForms() &&
                  !shouldIgnoreBlock &&
                  nextLocation.pathname !== location.pathname
                  ? "leave-unsaved-changes-notification-message--note"
                  : true
              }}
            />
          </main>
        </SchoolsWrapper>
      </div>
    </div>
  )
}

UserLayoutPure.propTypes = {
  sessionSchoolName: PropTypes.string,
  schoolId: PropTypes.number.isRequired,
  yearclasses: PropTypes.array,
  currentURL: PropTypes.string.isRequired,
  yearClassGrade: PropTypes.number,
  hasGoals: PropTypes.bool,
  hasMultipleSchools: PropTypes.bool.isRequired,
  isSchoolSuperuser: PropTypes.bool.isRequired,
}

const connected = withRouter(
  connect(mapStateToProps, {
    getSchool,
    changeSchool,
    clearErrors,
  })(dragDropContext(HTML5Backend)(UserLayoutPure)),
)

export default connected
