import React from "react"

import { sanitize } from "dompurify"
import { connect } from "react-redux"
import * as StorageAPI from "lib/core/storage.api"
import MLUButton, { MLUButtonSize } from "components/ui/mlubutton/mlubutton"
import ModalNotification from "components/modal-notification/modal-notification"
import { t } from "./school-disconnected-notification.i18n.js"
import { DoorbellFormContainer } from "entities/doorbell/doorbell-form.view.jsx"
import { SUPPORT_TICKET_OPTIONS } from "lib/leeruniek/constants.js"

export const STORAGE_KEY = "hideSchoolDisconnectedNotification"

interface SchoolDisconnectedNotificationProps {
  school: { id?: number; isConnectionActive?: boolean; name?: string }
}

const SchoolDisconnectedNotification = ({
  school,
}: SchoolDisconnectedNotificationProps) => {
  const valueFromStorage = StorageAPI.find(STORAGE_KEY)
  const schoolIdsFromStorage = valueFromStorage
    ? JSON.parse(valueFromStorage)
    : []
  const [isHidden, setIsHidden] = React.useState(
    schoolIdsFromStorage.includes(school.id),
  )
  const [isOpen, setIsOpen] = React.useState(
    school.isConnectionActive === false && !isHidden,
  )
  const [feedbackFormOpen, setFeedbackFormOpen] = React.useState(false)

  const handleClose = () => {
    if (isHidden && !schoolIdsFromStorage.includes(school.id)) {
      StorageAPI.upsert(
        {
          key: STORAGE_KEY,
          value: JSON.stringify([...schoolIdsFromStorage, school.id]),
        },
        { local: true },
      )
    }
    setIsOpen(false)
  }

  const handleHideNotificationToggled = () => {
    setIsHidden((prevIsHidden: boolean) => !prevIsHidden)
  }

  const handleOpenSupport = () => {
    setFeedbackFormOpen(true)
    handleClose()
  }

  const handleCloseSupport = () => {
    setFeedbackFormOpen(false)
  }

  return isOpen ? (
    <ModalNotification
      open
      title={t("connection-inactive")}
      actions={[
        <MLUButton
          key="btn-close"
          label={t("close")}
          size={MLUButtonSize.MEDIUM}
          // used by nightwatch tests
          dataTestId="school-disconnected-notification__close-btn"
          onClick={handleClose}
        />,
        <MLUButton
          key="btn-support"
          label={t("support")}
          size={MLUButtonSize.MEDIUM}
          color="yellow"
          onClick={handleOpenSupport}
        />,
      ]}
      hideNotification={isHidden}
      onHideNotificationToggled={handleHideNotificationToggled}>
      <span
        dangerouslySetInnerHTML={{
          __html: sanitize(
            t("disconnected-notification", { name: school.name }),
          ),
        }}
      />
    </ModalNotification>
  ) : feedbackFormOpen ? (
    <DoorbellFormContainer
      isVisible
      showDoorbellForm
      ticketOptions={SUPPORT_TICKET_OPTIONS.filter(
        (option) => option.value === "other",
      )}
      onClose={handleCloseSupport}
      onSubmit={handleCloseSupport}
    />
  ) : null
}

export default connect(
  (state: {
    school: {
      school: { id?: number; isConnectionActive?: boolean; name?: string }
    }
  }) => ({
    school: state.school.school,
  }),
)(SchoolDisconnectedNotification)
