import React from "react"

import { sanitize } from "dompurify"
import * as StorageAPI from "lib/core/storage.api"
import {
  getCurrentSchoolYear,
  isWithinSchoolYearStartPeriod,
} from "lib/leeruniek/time-helpers"
import MLUButton, { MLUButtonSize } from "components/ui/mlubutton/mlubutton"
import ModalNotification from "components/modal-notification/modal-notification"
import { t } from "./new-school-year-notification.i18n"
import { connect } from "react-redux"

export const STORAGE_KEY = "hideNewSchoolYearNotificationForSchoolYear"

interface NewSchoolYearNotificationProps {
  isSchoolSuperuser: boolean
}

const NewSchoolYearNotification = ({
  isSchoolSuperuser,
}: NewSchoolYearNotificationProps) => {
  const valueFromStorage = StorageAPI.find(STORAGE_KEY)
  const [isHidden, setIsHidden] = React.useState(
    Boolean(
      valueFromStorage && Number(valueFromStorage) === getCurrentSchoolYear(),
    ),
  )
  const [isOpen, setIsOpen] = React.useState(
    isSchoolSuperuser && isWithinSchoolYearStartPeriod() && !isHidden,
  )

  const handleClose = () => {
    if (isHidden) {
      StorageAPI.upsert(
        {
          key: STORAGE_KEY,
          value: getCurrentSchoolYear(),
        },
        { local: true },
      )
    }
    setIsOpen(false)
  }

  const handleHideNotificationToggled = () => {
    setIsHidden((prevIsHidden: boolean) => !prevIsHidden)
  }

  return isOpen ? (
    <ModalNotification
      open
      title={t("new-schoolyear")}
      actions={[
        <MLUButton
          key="btn-close"
          label={t("close")}
          size={MLUButtonSize.MEDIUM}
          onClick={handleClose}
        />,
      ]}
      hideNotification={isHidden}
      onHideNotificationToggled={handleHideNotificationToggled}>
      <span
        dangerouslySetInnerHTML={{
          __html: sanitize(t("new-school-year-notification")),
        }}
      />
    </ModalNotification>
  ) : null
}

export default connect(
  (state: { school: { school: { isSchoolSuperuser: boolean } } }) => ({
    isSchoolSuperuser: state.school.school.isSchoolSuperuser,
  }),
)(NewSchoolYearNotification)
