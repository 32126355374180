import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["disconnected-notification"]: {
    nl:
      `De koppeling tussen Leeruniek en ParnasSys is voor <b>{{name}}</b><br/> ` +
      `gedeactiveerd. Zonder deze koppeling is het niet mogelijk om gebruik<br/>` +
      `te blijven maken van Leeruniek.<br/><br/>` +
      `Neem zo snel mogelijk contact met ons op als dit niet de bedoeling is.<br/>` +
      `In het geval dat je geen gebruik meer wilt maken van Leeruniek, zullen <br/>` +
      `alle accounts en data volledig worden verwijderd.`,
    en:
      `The link between Leeruniek and ParnasSys is deactivated for <br/>` +
      `<b>{{name}}</b>. Without this link it is not possible to continue <br/>` +
      `using Leeruniek.<br/><br/>` +
      `Contact us as soon as possible if this is not the intention.<br/>` +
      `In case you no longer want to use Leeruniek, all accounts and data <br/>` +
      `will be completely deleted.`,
  },

  ["connection-inactive"]: {
    nl: "Koppeling gedeactiveerd",
    en: "Link deactivated",
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
