import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["hide-notification"]: {
    nl: "Laat dit bericht niet meer zien",
    en: "Do not show this message again",
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
